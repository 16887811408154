/*global SocialQAndA, Backbone*/
import Backbone from "backbone";
import _ from 'lodash';
import $ from 'jquery';
import FlexModel from 'tscom-flex';
import constants from '../util/constants';

'use strict';
export default FlexModel.extend({
    required_fields: [
      'id',
      'topics',
      'ruleset_collection_ids',
      'user_name',
      'message',
      'country',
      'state',
      'custom_parameters'
    ],

    optional_fields: [
      //'ruleset_collection_ids', //only needs this to go to tailor
      'screen_name',
      'timestamp',
      'image',
      'video',
      'avatar',
      'user_score',
      'dob'
    ],
    version_check: 'gwiuapsifugpasiufg',
    initialize: function(attrs, options) {

        if(options && options.version_id) {
            this.version_id = options.version_id;
        }
        if(options && options.geoCountry) {
            this.geoCountry = options.geoCountry;
        } else{
            this.geoCountry = "";
        }
        if(options && options.geoState) {
            this.geoState = options.geoState;
        } else {
            this.geoState = "";
        }

        this.apiUrl = options.apiUrl? options.apiUrl : this.apiUrl;
        this.apiUri = options.apiUri? options.apiUri : this.apiUri;


        console.log('flex init options', options);
    },
    setUpData: function (model) {
        console.log("flex 1",this, model);

        //map keys per TechOps request
        model = _.mapKeys(model, function(value, key){
          if(key === "state"){
            return "user_state";
          }else if(key === "country"){
            return "user_country";
          }else{
            return key;
          }
        });


        //extend model so that akamai data gets passed to custom_parameters
        _.extend(model, {
          akamai_data: {
            state: this.geoState || constants.flex.DEFAULT_VALUE,
            country: this.geoCountry || constants.flex.DEFAULT_VALUE
          }

        });

        var dob = ( data.birthdate_month && data.birth_day && data.birth_year) ?
        data.birthdate_month + '/' + data.birth_day + '/' + data.birth_year: constants.flex.DEFAULT_VALUE;

        this.set({
            'id': _.uniqueId(new Date().getTime()+''+Math.floor(Math.random()*99)),
        //    'ruleset_collection_ids': '',
            'user_name': model.user_name || constants.flex.DEFAULT_VALUE,
            'message': model.message || constants.flex.DEFAULT_VALUE,
            'dob': dob,
            'country': model.user_country || this.geoCountry,
            'state': model.user_state || this.geoState,
            'custom_parameters': JSON.stringify(model),
            'video': '',
            'image': ''
        });

        //Populate the optional Flex fields if the form model has matching fields.
        for(var i = 0; i < this.optional_fields.length; i++) {
            var name = this.optional_fields[i];
            var value = model[name];
            if(!_.isUndefined(value) && !_.isNull(value)) {
                this.set({name: value});
            }
        }
        console.log("flex 2",this);

    },
    save: function(attributes, options) {
      //Flex requires the ID to be set.
      if(this.get('id') === '' || this.get('id') === undefined || this.get('id') === null) {
        this.set( { 'id': this.generateUniqueID() }, { silent: true } );
      }

      //always set timestamp because it was overwriting others with same
      //timestamp
      this.set( { 'timestamp': Date.now() }, { silent: true } );

      var params = {
          url: this.urlRoot(),
          type: 'POST',
          dataType: 'JSON'
      };

      if(options && options.url) {
        options.url = this.prepareUrl(options.url);
      }

      _.extend(params, options);

      return Backbone.Model.prototype.save.call(this, attributes, params);
    }
});