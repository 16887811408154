module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"<%= name %>\"> <h1 style=\"<%= style %>\"><%= headline1 %></h1> <p style=\"<%= style %>\"><%= paragraph1 %></p> <a href=\"#<%= button.route %>\" class=\"btn btn-close<%= (button.display === 'true')? '' : ' hidden' %>\"><%= button.text %></a> </div>"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"")
    ; __append(escapeFn( name ))
    ; __append("\"> <h1 style=\"")
    ; __append(escapeFn( style ))
    ; __append("\">")
    ; __append(escapeFn( headline1 ))
    ; __append("</h1> <p style=\"")
    ; __append(escapeFn( style ))
    ; __append("\">")
    ; __append(escapeFn( paragraph1 ))
    ; __append("</p> <a href=\"#")
    ; __append(escapeFn( button.route ))
    ; __append("\" class=\"btn btn-close")
    ; __append(escapeFn( (button.display === 'true')? '' : ' hidden' ))
    ; __append("\">")
    ; __append(escapeFn( button.text ))
    ; __append("</a> </div>")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}