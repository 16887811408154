'use strict';
import _ from "lodash";
import Backbone from "backbone";
import template from './../templates/loading.ejs';

export default Backbone.View.extend({

    template: template,
    tagName: 'div',
    className: 'view-loading',
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function () {
        this.$el.html(this.template(this.model));
        return this;
    }

});