'use strict';
import _ from "lodash";
import Backbone from "backbone";
import template from '../templates/css.ejs';

export default Backbone.View.extend({
    tagName: 'style',
    className: 'view-css',
    initialize: function(options) {
        options = options || {};
        _.bindAll(this, 'render');
        
        this.template = options.template || template;
        this.namespace = options.namespace || '';
        this.namespaceModal = options.namespaceModal || '';
        this.uid = options.uid || '';
        this.model = _.extend(_.clone(this.model, 'true'), {
            namespace: this.namespace,
            namespaceModal: this.namespaceModal,
            uid: this.uid
        });

        this.$el.prop('type', 'text/css');
    },
    /**
     *
     */
    render: function() {
console.log("cssview ",this.model);
        this.$el.append(this.template(this.model));
        return this;
    },
    remove: function() {
        this.template = null;
        this.namespace = null;
        this.namespaceModal = null;
        this.model = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});
