/*global SocialQAndA, Backbone*/
import Backbone from "backbone";
import _ from 'lodash';
import modelModeration from 'tscom-moderation';
import { cleanObject } from '../util/helpers';

'use strict';
export default modelModeration.extend({

    defaults: {
        // put here instead of urlParams because it will be easier to reset this attribute
        // format: string separated by commas: "facebook,twitter,instagram". empty string mean no filter
        sources: "",

        // item count: number of items each request
        itemCount: 50,

        // filter out obscene contents
        noProfanity: true,

        // when no more old messages to fetch
        noMoreOldMessages: false,

        // Put all incoming raw data from API here
        messages: [],
        allMessages: [],
        newMessages: [],
        oldMessages: [],

        itemsLength: 0,
        itemsPreviousLength: 0,

        // the position of last social moderation item
        // - use this position to request older items from moderation API
        newestPosition: '',
        oldestPosition: '',
        offset: '20',
        initialized: false,
        poll: true,
        /**
         * The number of seconds to wait before updating again.
         */
        updateFrequency: 30,
        pollTimer: null,
        /**
         *
         */
        failureCount: 0,
        /**
         *
         */
        failureLimit: 10,

        // settings of ajax requests
        options: {
            offset: null,
            position: null,
            // Social Moderation tool topic ID
            topic_id: null,

            // contains - filter for only image or only video
            // if empty, contains all item types, but most of them will be text-only
            // example: "contains_image, contains_video" will give you images OR videos
            contains: "",

            // filter out repeated items
            noRetweet: true
        }
    },


    fetchOlderMessages: function () {
        //note: positive offset for exclusive
        var fetchParams = {
            topic_id: this.get('topic_id'),
            offset: '-' + this.get('offset'),
            position: this.get('oldestPosition')
        };

        var options = _.extend({
            data: fetchParams
        }, {
            apiUrl: this.apiUrl
        });

        cleanObject(options.data);

        console.log('fetching older messages');

        return this.fetch(options, this.fetchOldSuccess.bind(this));


    },
    fetchNewMessages: function () {

        //note: negative offset for inclusive
        var fetchParams = {
            topic_id: this.get('topic_id'),
            offset: '-' + this.get('offset')
        }

        var options = _.extend({
            data: fetchParams
        }, {
            apiUrl: this.apiUrl
        });

        cleanObject(options.data);

        console.log('fetching new messages');

        return this.fetch(options, this.fetchNewSuccess.bind(this));

    },

    fetch: function (options, cb) {

        return Backbone.Model.prototype.fetch.call(this, options).done(cb);

    },
    fetchOldSuccess: function (response) {
        console.log('fetchOldSuccess()');
        if (_.isEmpty(response)) {
            this.set({
                oldMessages: [],
            });

        } else {
            this.set({
                allMessages: this.get('allMessages').concat(this.get('messages')),
                oldMessages: this.get('messages')
            });

            this.updatePositions(response);
        }

        // when the last fetch is shorter than the offset, we know that we've gotten the oldest message
        if (this.get('messages').length < this.get('offset')) {
            this.set({
                noMoreOldMessages: true
            });
        }

        this.trigger('moderation:old-messages');
    },
    fetchNewSuccess: function (response) {
        console.log('fetchNewSuccess()');

        if (_.isEmpty(response)) {
            this.set({
                newMessages: [],
            });

        } else {

            var newMessages = [];
            var messages = [];
            var newestPosition = this.get('newestPosition');

            _.each(response, function (message, key) {
                messages.push(message);
                if (!newestPosition || parseInt(message.position, 10) < parseInt(newestPosition, 10)) {
                    newMessages.push(message);
                }
            });


            this.set({
                allMessages: newMessages.concat(this.get('allMessages')),
                newMessages: newMessages
            });

            this.updatePositions(response);

            if (newMessages.length > 0) {
                this.trigger('moderation:new-messages');
            }

        }

        // initialize on first fetch
        if (!this.get('initialized')) {
            this.set({
                initialized: true
            });
        }

        if (this.get('poll')) {
            this.pollData();
        }

    },
    updatePositions: function (response) {
        var newestPosition = this.get('newestPosition');
        var oldestPosition = this.get('oldestPosition');

        var positions = {
            newest: parseInt(response[0].position, 10),
            oldest: parseInt(response[_.size(response) - 1].position, 10) + 1
        };

        if (newestPosition) {
            newestPosition = positions.newest < parseInt(newestPosition, 10) ? positions.newest : newestPosition;
        } else {
            newestPosition = positions.newest;
        }

        if (oldestPosition) {
            oldestPosition = positions.oldest > parseInt(oldestPosition, 10) ? positions.oldest : oldestPosition;
        } else {
            oldestPosition = positions.oldest;
        }

        console.log('updatePosition() -> oldestPosition', oldestPosition);
        console.log('updatePostion() -> newestPosition', newestPosition);


        this.set({
            oldestPosition: oldestPosition,
            newestPosition: newestPosition
        });
    },
    /**
     * sets our polling timer
     */
    pollData: function () {
        console.log('pollData');
        if (this.get('failureCount') >= this.get('failureLimit')) {
            return;
        }
        this.set({
            pollTimer: setTimeout(this.fetchNewMessages.bind(this), parseInt(this.get('updateFrequency'), 10) * 1000)
        });
    },
    /**
     * Stop polling and clear the timer
     */
    stopPolling: function () {
        var pollTimer = this.get('pollTimer');
        if (typeof pollTimer == 'number') clearTimeout(pollTimer);
        this.set('pollTimer', null);
    },

    parse: function (response) {
        var ret = [];

        _.each(response, function (message, key) {

            ret[key] = this.updateWithCloudfront(message);

        }.bind(this));

        return {
            messages: ret
        };
    },

    updateWithCloudfront: function (message) {
        try {
            var flexCustomParameters = message.flex_custom_parameters;
            var customParameters = JSON.parse(flexCustomParameters);
            if (customParameters.cloudfront_url) {
                var cf = customParameters.cloudfront_url;

                var video = message.video;
                var images = message.images;
                var re = new RegExp(/(http|https)\:\/\/s3([a-z0-9\-]+?).amazonaws\.com\/(.+?)\//gi);
                video = video.replace(re, cf);
                images = images.replace(re, cf);

                message.images = images;
                message.video = video;

            }
        } catch (e) {
            //Do nothing
        }
        return message;
    }
})
