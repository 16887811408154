'use strict';

const widget = require('./Widget').default;

require('./util/plugin');
require('../styles/main.scss');

/**
 *
 * @param options
 * {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-rev' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // absolute path
 * cssIsUnique: true // boolean - if true css should use an ID selector for the namespacing, otherwise use a class selector for the namespacing and restrict loading and injection of style block to a single execution
 * hashState: false // boolean - when true, enables hashable routes **for testing - only set to true when instantiation of the widget is limited to one object per frameElement/window
 * endpoints: {cms: '//widgetstate.votenow.tv/v1/state'} // object
 * }
 */
module.exports = function(options){

    options = options || {
        widget_id: '81b0b01197f10944',
        container: document.querySelector("#TelescopeWidgetVideoUploader"),
        modal: document.getElementsByTagName('body')[0],
        hashState: false
    };

    options.container = options.container || document.body;
    options.modal = options.modal || options.container;

    options.uniqueId = options.uniqueId || options.container.id;

    widget(options);

};
