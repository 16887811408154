'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import template from './../templates/userform.ejs';
import templateCss from './../templates/userform.css.ejs';
import BackboneValidation from "backbone-validation";
import FormView from 'uploaderform/app/scripts/views/form';
import CSSView from './css';
import PubSub from './../PubSub';

import { dateDiffInDays } from '../util/helpers';

export default FormView.extend({
    template: template,
    className: 'view-userform',
    events: {
        'click  .btn-submit.active'         : 'formSubmit',
        'click  .cancel'                    : 'cancel',
        'change .dob-container select'      : 'updateDOBValues',
        'submit .user-form'                 : 'handleFormSubmit',
        'click   select'                    : 'selectClickHandler'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render', 'handleInvalid', 'handleValid', 'handleFieldValueChange', 'renderErrors', 'handleFormSubmit');
        this.uid = options.uid;
        this.styleID = this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
        this.$el.addClass(this.styleID);
        this.copy = options.copy;
        this.storage = options.storage;
        this.scrollToFramePosition = options.scrollToFramePosition;
        this.isIframed = options.isIframed;

        // VU-165: Fix for select option text overlapping with svg icon
        this.selectWidthSet = false;

        /* Variables for new select_dob field */
        this.cms = options.cms;
        this.dob = {};
        this.oldEnough = false;

        if(_.findWhere(this.model.fieldData, {type: 'select_dob'})) {
            this.oldEnough = false;
            this.ageGateEnforced = (this.copy.restrict_user_age == 'true' ? true : false);
        } else {
            this.oldEnough = true;
        }

        // if ageGate is enforced, determine if ageGate exists
        if(this.ageGateEnforced && this.storage.fetch('ag_uploader')) {
            var ageGate = this.storage.fetch('ag_uploader');

            if(ageGate) {
                var _MS_PER_DAY = (1000 * 60 * 60 * 24);
                if(!(parseInt(ageGate) < (this.cms.getServerTime() - _MS_PER_DAY))) {
                    this.ageGate = true;
                } else {
                    this.ageGate = false;
                    this.storage.destroy('ag_uploader');
                }
            }
        }

        this.dobName = _.reduce( this.copy.fields , function ( memo , field ){
            if( field.type === "select_dob" && memo === undefined ) memo = field.id;
            return memo;
        } , undefined );

        BackboneValidation.bind(this, {
            model: this.model
        });

        this.listenTo(this.model, 'validated:invalid', this.handleInvalid);

        this.listenTo(this.model, 'validated:valid', _.bind(function() {
            // if the select DOB is an attribute, verify the users age based on limit
            if(_.findWhere(this.model.fieldData, {type: 'select_dob'})) {
                // ensure the confirm email has been validated correctly
                if(!this.oldEnough) {
                    this.$el.find('#invalid-dob').show();
                    return;
                }
            }

            // if not, display error on confirm
            // check that all the fields are completed and submit the application
            this.handleValid();
        }, this));

        this.listenTo(this.model, 'validated:valid', this.handleValid);
        this.listenTo( PubSub, "windowResize", _.bind( this.setSelectInputWidth, this ) );

    },
    /**
     *
     */
    render: function () {
        if(this.CSSView) {
                this.CSSView.remove();
        }
        $(document).load().scrollTop(0);
        this.CSSView = new CSSView({
                model: this.copy['customizations'],
                namespace: '#' + this.uid + ' .view-userform.' + this.styleID,
                template: templateCss
        });
        this.copy.styleID = this.styleID;
        this.copy.columns = 0;

        this.buildDOB();

        this.$el.html(this.template(this.copy));

        Backbone.$('head').append(this.CSSView.render().$el);

        return this;
    },
    /**
     *
     */
    renderedToDOM: function() {
        if(this.ageGate) {
            PubSub.trigger('age-gate');
            return;
        }

    },
    /**
     * [handleFormSubmit description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleFormSubmit: function(e) {
        console.log('FORM: handleFormSubmit');
        e.preventDefault();

        if(!this.oldEnough && !this.ageGateEnforced) {
            this.$el.find('#invalid-dob').show();
        } else if(this.ageGateEnforced && !this.oldEnough) {
            var DD = this.dob['day'],
                MM = parseInt(this.dob['month']),
                YYYY = this.dob['year'];

            var inputtedDate = new Date(YYYY, MM - 1, DD).getTime();

            if(!_.isNaN(inputtedDate)) {
                this.userAgeGate();
                return;
            } else {
                this.$el.find('#invalid-dob').show();
            }
        }

        var params = {};
        var form = this.el.getElementsByClassName('user-form')[0];

        _.each(form.elements, function(data) {
            if(data.name === ""){
                return;
            }

            if(data.type === "checkbox"){
                params[data.name] = data.checked ? "true" : "false";
                // params[data.name] = data.checked ? "Y" : "N";
            } else {
                params[data.name] = data.value;
            }
        });

        this.model.set(params, { silent: true });

        this.model.save();
    },
    /**
     * [handleBlurValidation description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleBlurValidation: function(e) {
        console.log('FORM: handleBlurValidation');
        var field = $(e.target);
        var value = field.val();
        if(field.prop('type').toLowerCase() === 'checkbox') {
            value = field.is(':checked');
        }
        var error = this.model.preValidate(field.prop('name'), value);

        if (error) {
            var errors = {};
            errors[field.prop('name')] = error;
            this.renderErrors(errors);
        } else {
            field.closest('.has-error').removeClass('has-error');
        }
    },
    /**
     * [handleInvalid description]
     * @param  {[type]} model  [description]
     * @param  {[type]} errors [description]
     * @return {[type]}        [description]
     */
    handleInvalid: function(model, errors) {
        console.log('FORM: handleInvalid', model, errors);
        this.$('.has-error').removeClass('has-error');

        this.enableForm();
        this.renderErrors(errors);
    },
    /**
     * [handleValid description]
     * @param  {[type]} model [description]
     * @return {[type]}       [description]
     */
    handleValid: function(model) {
        console.log('valid');
        this.updateDOBinModel();

        this.trigger('formSubmit', this.model.attributes);
    },
    /**
     *
     */
    userAgeGate: function() {
        this.storage.save('ag_uploader', this.cms.getServerTime(), 1);
        PubSub.trigger('age-gate');
    },
    /**
     *
     */
    renderErrors: _.debounce(function(errors) {

        var styleID = this.styleID;
        var minYPos = 0;
        var isIframed = this.isIframed;
        var $elToBringToFocus = null;

        _.each(errors, function(error, name) {

            var $el = this.$('#' + name + styleID);
            var elYPos = $el.offset().top;

            // get the error with lowest y position
            if ( elYPos <  minYPos || !minYPos ) {
                minYPos = elYPos;
                $elToBringToFocus = $el;
            }

            switch($el.attr('type')){

                case "text":
                    this.$('[name="' + name + '"]').parent().addClass('has-error');
                    break;

                case "checkbox":
                    this.$('[name="' + name + '"]').closest('.row').addClass('has-error');
                    break;

                default:
                    if($el.prop('nodeName') === "SELECT"){
                        this.$('[name="' + name + '"]').closest('.columns').addClass('has-error');
                    }
                    //other conditions here
            }

            if(name === "upload_video" || name === "upload_photo") {
                this.fileClear();
            }

        }.bind( this ));

        // scroll only if there is an error
        if ( minYPos && isIframed ) {
            return this.scrollToFramePosition( minYPos );
        }

        // no iframe has 100% height styling
        // since element position can't be found, use scrollIntoView instead
        if ( $elToBringToFocus.length ) {
            $elToBringToFocus.parent()[0].scrollIntoView();
        }
    }, 200, { leading: false, trailing: true }),
    /**
     * [cancel description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    cancel: function(e) {
        e.preventDefault();
        this.trigger('cancel', {});
    },
    /**
     * [remove description]
     * @return {[type]} [description]
     */
    remove: function() {
        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.copy = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    },
    /**
     *
     */
    updateDOBValues: function(e) {
        var $target = $(e.target),
            value = $target.val(),
            name = $target.data('type');

        this.dob[name] = value;
        this.verifyAge();
    },
    /**
     *
     */
    verifyAge: function() {
        console.log('verifyAge', _.size(this.dob));
        if( !this.dob[ 'day' ] && !this.dob[ 'month' ] && !this.dob[ 'year' ] ) return;

        this.$el.find('#invalid-dob').hide();

        var DD = this.dob['day'],
            MM = parseInt(this.dob['month']),
            YYYY = this.dob['year'];

        // console.log('AGE is:', MM, DD, YYYY);

        var age = parseInt(this.copy.age_limit) || 18;

        var serverTime = new Date(this.cms.getServerTime()),
            inputtedDate = new Date(YYYY, MM - 1, DD).getTime();

        if(_.isNaN(inputtedDate)) return;

        var inputtedDay = new Date(YYYY, MM - 1, DD);

        var dateDiffInDays = dateDiffInDays({ a: inputtedDay, b: serverTime }),
            ageLimit = Math.round( 365.242 * age ),
            _MS_PER_DAY = (1000 * 60 * 60 * 24),
            isTooYoung = !(dateDiffInDays >= ageLimit);

        this.oldEnough = (dateDiffInDays >= ageLimit);

        // if the ageGate is not enforced, display the error when a user updates their DOB
        if(!this.ageGateEnforced) {
            this.oldEnough ? this.$el.find('#invalid-dob').hide() : this.$el.find('#invalid-dob').show();
        }
    },
    /**
     *
     */
    buildDOB: function() {
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var months = [""], days = [""], years = [""];

        for(var i = 1; i < 32; i++) {
            if(i < 13) months.push(i);
            days.push(i);
        }

        for( var i = ( ( new Date() ).getYear() + 1900 ); i > 1900; i-- ) {
            years.push(i);
        }

        _.extend( this.copy , { months: months , days: days , years: years } );
    },

    /**
     * [updateDOBinModel description]
     * @return {[type]} [description]
     */
    updateDOBinModel: function(){
        var dobContainers = this.$( '.dob-container' ).find( 'select' );

        if( dobContainers.length ){

            var formattedDOB = _.reduce( dobContainers , function( accumulator , item) {
                var tempValue = item.value;

                if(parseInt(item.value) < 10) {
                    tempValue = "0" + item.value;
                }

                accumulator.push( tempValue );
                return accumulator;
            }, [] );

            // updated this portion due to NBC format requirements, per Doug
            var formattedNew = formattedDOB.reverse().join( '-' );

            this.model.set( this.dobName , formattedNew );

        }
    },
    /**
     * Sets the max width on select inputs
     */
    setSelectInputWidth: function(){
        var parentWidth = this.$( '.styled-select' ).outerWidth();
        this.$( "select" ).css( 'width', parentWidth + "px" );
    },
    /**
     * handler to check whether to set the width of the select elements
     * @return {[type]} [description]
     */
    selectClickHandler: function(){
        if( !this.selectWidthSet ){
            this.setSelectInputWidth();
            this.selectWidthSet = true;
        }
    }
});





