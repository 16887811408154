'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import template from './../templates/submit.ejs';
import templateCss from './../templates/submit.css.ejs';
import PubSub from '../PubSub';
import CSSView from './css';
import ModelTailor from './../models/tailor';
import ViewMedia from './media';

var $textarea,
    max,
    $charcount,
    textareaVal = "";


export default Backbone.View.extend({
    template: template,
    className: 'view-submit',
    events: {
        'click .btn-upload': 'uploadFile',
        'change .file-input': 'fileUploaded',
        'click .btn-submit.active': 'submitForm',
        'click .btn-submit': 'preventDefault',
        'touchstart textarea': 'handleTextArea',
        'change textarea': 'handleTextArea',
        'keyup textarea': 'handleTextArea',
        'paste textarea': 'handleTextAreaPaste',
        'input textarea': 'handleTextArea',
        'click .upload-error': 'emptyError',
        'click .cta-gallery a': 'handleRoute',
        'keyup form .description': 'updateCharCount'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render', 'fileUploaded', 'handleTextArea');
        this.uid = options.uid;
        this.styleID = this.className + _.uniqueId(Math.random() + "" + new Date().getTime()).replace('.', '');

        this.tailorOptions = options.tailorOptions;
        this.videoFormats = options.videoFormats;
        this.imageFormats = options.imageFormats;
        this.mediaView = options.mediaView;
        this.errorCodes = options.errorCodes;
        this.$el.addClass(this.styleID);
        this.file = {};
        this.globalStyles = options.globalStyles;
        this.uploadSuccess = false;

        this.fileUploadError = false;
    },
    /**
     *
     */
    render: function () {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: _.extend(this.globalStyles, this.model['customizations']),
            namespace: '#' + this.uid + ' .view-submit.' + this.styleID,
            template: templateCss
        });
        Backbone.$('head').append(this.CSSView.render().$el);

        this.model.styleID = this.styleID;
        this.$el.html(this.template(this.model));

        if (this.mediaView) {
            this.mediaView.remove();
            this.mediaView = null;
        }

        this.mediaView = new ViewMedia({
            model: {},
            uid: this.uid
        });

        this.mediaView.model = {
            'video': this.model.video_embed_url,
            'images': this.model.image
        };

        var image = this.model.image;
        var vidEmbed = this.model.video_embed_url;
        if ((image.length > 0) || (vidEmbed.length > 0)) {
            this.$('.main-embed').html(this.mediaView.render().$el);
        }

        $textarea = this.$('.description');
        $charcount = this.$('.char-count');
        max = parseInt($textarea.attr('data-maxlength'));

        return this;
    },
    renderedToDOM: function () {
        this.mediaView.renderedToDOM();
        this.updateCharCount();
        this.$('.btn-submit')
            .attr('disabled', 'disabled');
    },
    /**
     *
     */
    uploadFile: function (e) {
        console.log('upload file', e);

        //click file input
        this.el.getElementsByClassName("file-input")[0].click();
        e.preventDefault();
    },
    /**
     *
     */
    fileUploaded: function (e) {
        console.log('file uploaded', e.currentTarget.value);

        //Don't do anythign if the user cancels.
        if(e.currentTarget.value === '') {
            return;
        }

        var re = /(?:\.([^.]+))?$/;
        var extension;
        try {
            extension = re.exec(e.currentTarget.value)[1].toLowerCase();
        } catch (e) {
            extension = 'none';
        }

        this.updateUploadText("loading");
        this.makeContinueInactive();


        var hasValidVideo = this.videoFormats.indexOf(extension) !== -1;
        var hasValidImage = this.imageFormats.indexOf(extension) !== -1;

        var imageUploadEnabled = this.model.buttons.photo_upload.display === "true";
        var videoUploadEnabled = this.model.buttons.video_upload.display === "true";
        var combinedUploadEnabled = this.model.buttons.combined_upload.display === "true";

        if ((imageUploadEnabled && hasValidImage) || (videoUploadEnabled && hasValidVideo) || (combinedUploadEnabled && (hasValidVideo || hasValidImage))) {

            //valid file format
            this.file = {
                'file': [e.currentTarget.files[0]],
                'name': e.currentTarget.value
            };

            //get and set image dimensions
            if (hasValidImage && (imageUploadEnabled || combinedUploadEnabled)) {
                var _URL = window.URL || window.webkitURL;
                var image = new Image();

                image.onload = _.bind(function (e) {
                    console.log("image dimensions " + e.target.width + " " + e.target.height);
                    this.createNewTailorModel();
                    this.tailorModel.setImageDimensions(e.target.width, e.target.height);
                    this.tailorStart();
                }, this);

                image.src = _URL.createObjectURL(this.file.file[0]);
            } else {
                //video upload
                this.createNewTailorModel();
                this.tailorStart();
            }
        } else if (extension === "none") {
            //Android ~4.4 Native Browser gives no filetype extensions
            this.updateUploadText('no_extension');
            this.file = {};
        } else {
            this.updateUploadText("10810"); //wrong file type error
            this.file = {};
            //invalid file format
            this.trigger('error', {
                'name': "file_type"
            });
            //this.trigger('videoUploaded', {
            //    'file': [e.currentTarget.files[0]],
            //    'name': e.currentTarget.value
            //});
        }

        $("input[type='file'].file-input").replaceWith($("input[type='file'].file-input").clone(true));
    },
    tailorStart: function () {
        this.stopListening(this.tailorModel, 'success:jobs');
        this.stopListening(this.tailorModel, 'success:flex');

        this.tailorModel.set('files', this.file.file);
        this.tailorModel.save();

        this.listenTo(this.tailorModel, 'success:jobs', _.bind(function () {
            this.tailorModel.tailorUpload();
        }, this));
        this.listenTo(this.tailorModel, 'success:flex', _.bind(function () {
            this.tailorModel.tailorUpload();
        }, this));

    },
    updateUploadText: function (type) {
        type = (typeof type !== 'undefined') ? type : "";
        var upload = this.el.getElementsByClassName("upload-text")[0];
        upload.classList.remove("upload");
        upload.classList.remove("error-file");
        upload.classList.remove("success");

        if (type === "loading") {
            this.uploadSuccess = false;
            upload.classList.add("upload");
            // this.makeContinueInactive();
        } else if (type === "custom_error") {
            this.uploadSuccess = false;
            this.fileUploadError = true;
            upload.classList.add("error-file");
            this.file = {};
            this.el.getElementsByClassName("error-text-span")[0].innerHTML = this.errorCodes["custom_error"]["text"] || this.model.buttons.video_upload.upload_error;
        } else if (this.errorCodes[type] || type === "error") {
            this.uploadSuccess = false;
            this.fileUploadError = true;
            upload.classList.add("error-file");
            this.file = {};
            //update error text
            this.el.getElementsByClassName("error-text-span")[0].innerHTML = this.errorCodes[type] || this.model.buttons.video_upload.upload_error;
            // this.makeContinueInactive();
        } else if (type === "success") {
            this.uploadSuccess = true;
            upload.classList.add("success");
            this.fileUploadError = false;
            // this.makeContinueActive();
        }
        this.checkIfActive();
    },
    /**
     * Make sure maxlength on textarea is respected in all devices
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleTextAreaPaste: function (e) {
        e.preventDefault();
        var text = $textarea.val();
        var start = text.slice(0, e.target.selectionStart);
        var end = text.slice(e.target.selectionEnd);
        console.log('start:', '"' + start + '"', 'end:', '"' + end + '"', 'text:', '"' + text + '"');
        try { //for Chrome and FF
            text = (
                start +
                e.originalEvent.clipboardData.getData('Text') +
                end
            );
        } catch (err) {
            try { // for IE
                text = (
                    start +
                    clipboardData.getData('Text') +
                    end
                );
            } catch (err) { // alternative method?!
                text = (
                    start +
                    e.clipboardData.getData('Text') +
                    end
                );
            }
        }

        if (text.length > max) {
            text = text.slice(0, max);
        }

        console.log('pasted is', text);
        $textarea.val(text);
        textareaVal = text;

        this.expandTextArea();
        this.checkIfActive();
        this.updateCharCount();
    },
    handleTextArea: function (e, pause) {
        console.log('handle text area', e);
        //don't allow continue button to change if there's a file upload error
        // if(!this.fileUploadError) {
        //     if(e.target.value.length > 0) {
        //         this.makeContinueActive();
        //     } else if(this.file === {}) {
        //         this.makeContinueInactive();
        //     }
        // }
        textareaVal = $textarea.val();
        if (textareaVal.length > max) {
            textareaVal = textareaVal.slice(0, max);
            $textarea.val(textareaVal);
        }

        this.expandTextArea();
        this.checkIfActive();
        this.updateCharCount();
    },
    checkIfActive: function () {
        if (!this.fileUploadError) {
            //no file upoload error
            var description = this.$(".description").val() || '';
            var emptyFile = Object.keys(this.file).length === 0 && JSON.stringify(this.file) === JSON.stringify({})

            if (this.model.description.show !== "false" &&
                this.model.description.field_required === "true" &&
                !description.length
            ) {
                this.makeContinueInactive();
                return;
            }

            if (emptyFile &&
                this.model.buttons.combined_upload.required === "true" &&
                this.model.buttons.combined_upload.display === "true"
            ) {
                this.makeContinueInactive();
                return;
            }

            if (emptyFile &&
                this.model.buttons.photo_upload.required === "true" &&
                this.model.buttons.photo_upload.display === "true" &&
                this.model.buttons.combined_upload.display === "false"
            ) {
                this.makeContinueInactive();
                return;
            }

            if (emptyFile &&
                this.model.buttons.video_upload.required === "true" &&
                this.model.buttons.video_upload.display === "true" &&
                this.model.buttons.combined_upload.display === "false"
            ) {
                this.makeContinueInactive();
                return;
            }

            if (description.length || this.file.file) {
                if (description.length > max || !this.uploadSuccess) {
                    this.makeContinueInactive();
                    return;
                }

                this.makeContinueActive();

            } else {
                this.makeContinueInactive();
            }
        }
    },
    makeContinueActive: function () {
        this.$('.btn-submit')
            .addClass('active')
            .removeAttr('disabled');
    },
    makeContinueInactive: function () {
        this.$('.btn-submit')
            .removeClass('active')
            .attr('disabled', 'disabled');
    },
    submitForm: function (e) {
        e.preventDefault();

        if (this.model.description.show !== "false") {
            this.file.message = this.el.getElementsByClassName("description")[0].value;
        }
        this.stopListening(this.tailorModel);
        //this.trigger('fileUploaded', this.file);
        this.trigger('fileUploaded', {
            fileData: this.file,
            tailorModel: this.tailorModel
        });

    },
    preventDefault: function (e) {
        e.preventDefault();
    },
    emptyError: function (e) {
        this.updateUploadText("");
    },
    handleRoute: function (e) {
        e.preventDefault();
        PubSub.trigger("navigate:gallery");
    },
    remove: function () {
        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.videoFormats = null;
        this.imageFormats = null;
        this.file = null;
        this.fileUploadError = null;
        this.errorCodes = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    },

    /**
     * update character count to show the user the remaining characters he/she can input
     */
    updateCharCount: function (e) {
        if ($textarea.length) {
            var charLeft = max - $textarea.val().length;

            this.$('.char-count').html(charLeft);
        }
    },
    /**
     * Expand the height of the textarea to prevent the scrollbar from appearing
     * @return {[type]} [description]
     */
    expandTextArea: function () {
        $textarea.css('height', '53px');
        if ($textarea[0].scrollHeight > $textarea.outerHeight()) {
            $textarea.css('height', $textarea[0].scrollHeight + 30 + 'px');
        }
    },
    /**
     * we need to create a new tailor every time the file changes so that the job_id changes as well.
     * if we don't do this and the initial job fails, all subsequent jobs will fail (unless we reload the page)
     * as well since the job_id does not change
     * @return {[type]} [description]
     */
    createNewTailorModel: function () {
        this.tailorModel = new ModelTailor({
            apiUrl: this.tailorOptions.apiUrl
        }, {
            auth_token: this.tailorOptions.auth_token,
            geoCountry: this.tailorOptions.geoCountry,
            geoState: this.tailorOptions.geoState,
            topics: this.tailorOptions.topics,
            rulesetCollectionIds: this.tailorOptions.rulesetCollectionIds,
            endpoints: {
                jobs: this.tailorOptions.apiUrl + '/1.0/jobs',
                start: this.tailorOptions.apiUrl + '/1.0/jobs',
                upload: this.tailorOptions.apiUrl + '/1.0/upload',
                resumable: this.tailorOptions.apiUrl + '/1.0/resumable_upload/video'
            }
        })
        this.tailorModel.setUpData({});


        this.listenTo(this.tailorModel, 'success:upload', _.bind(function () {
            this.updateUploadText("success");
        }, this));
        this.listenTo(this.tailorModel, 'error', _.bind(function (model, resp, options) {
            if (typeof resp.responseText != 'undefined') {
                //use error code from tailor
                var errorCodesFromTailor = JSON.parse(resp.responseText).errorCodes;

                //check to see if custom error should be thrown
                var customErrorCMS = this.errorCodes["custom_error"];
                var errorsForCustom = customErrorCMS && customErrorCMS["error_codes"] && customErrorCMS["error_codes"].split(",");

                /*
                    The idea here is that all error_codes in custom_error must exist in the Tailor error response in order
                    for the custom error to be triggered.
                 */
                var userTailorErrorMessage = errorCodesFromTailor.length > 1 && _.reduce(errorsForCustom, function (res, code) {
                    return res && errorCodesFromTailor.indexOf(code) >= 0;
                }, true);

                if (userTailorErrorMessage) {
                    this.updateUploadText(errorCodesFromTailor[0]);
                } else {
                    this.updateUploadText("custom_error");
                }
            } else {
                this.updateUploadText("error");
            }
        }, this));
    }
});
