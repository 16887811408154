/*global SocialQAndA, Backbone*/
import Backbone from "backbone";
import _ from 'lodash';
import $ from 'jquery';
import TailorModel from 'tscom-tailor';
import PubSub from './../PubSub';
import constants from '../util/constants';

import { isAndroid } from '../util/helpers';

'use strict';
export default TailorModel.extend({

    /**
     * Initialize
     * @param  {Object} attrs   [description]
     * @param  {Object} options [description]
     */
    initialize: function(attrs, options) {

        this.auth_token = options.auth_token;
        this.geoCountry = (options.geoCountry)?options.geoCountry:'';
        this.geoState = (options.geoState)?options.geoState:'';
        this.topics = (options.topics)?options.topics:'';
        this.on('change:files', _.bind(this.onFilesChange, this));
        // this.rulesetCollectionId = options.rulesetCollectionId;
        this.rulesetCollectionIds = options.rulesetCollectionIds;
        this.userIP = null;
        this.dataSet = null;
        //if endpoints are not specified, default to prod urls
        var endpoints = {
            jobs: 'https://tailor.telescope.tv/1.0/jobs',
            start: 'https://tailor.telescope.tv/1.0/jobs',
            upload: 'https://tailor.telescope.tv/1.0/upload',
            resumable: 'https://tailor.telescope.tv/1.0/resumable_upload/video',
        }
        this.endpoints = options.endpoints? options.endpoints : endpoints;

        TailorModel.prototype.initialize.call(this, attrs, options);
    },
    /**
     * [setUpData description]
     * @param  {[type]} data   [description]
     */
    setUpData: function (data) {

        console.log('MPH-33: setUpData: ', this.userIP);
        data = _.extend(data, this.get('videoInfo'));

        var keyMap = {
            state: "user_state",
            country: "user_country"
        };

        var newData = {};

        _.each(data, function(value, key){
            key = keyMap[key] || key;
            newData[key] = value;
        });


        data = _.extend(newData, {
            'akamai_data': {
                state: this.geoState || constants.flex.DEFAULT_VALUE,
                country: this.geoCountry || constants.flex.DEFAULT_VALUE
            },
            'source_media': '${upload:1}'
        });

        var dob = ( data.birthdate_month && data.birth_day && data.birth_year) ?
        data.birthdate_month + '/' + data.birth_day + '/' + data.birth_year: constants.flex.DEFAULT_VALUE;

        var flex = {
            'id': _.uniqueId(new Date().getTime()+''+Math.floor(Math.random()*99)),
            'topics': this.topics,
            'user_name': data.user_name || constants.flex.DEFAULT_VALUE,
            'message': data.message || constants.flex.DEFAULT_VALUE,
            'dob': dob,
            'country': data.user_country || this.geoCountry,
            'state': data.user_state || this.geoState,
            'custom_parameters': JSON.stringify(data),
            //'video': data.video,
            // 'ruleset_collection_id': this.rulesetCollectionId.length > 0 ? this.rulesetCollectionId : "0",
            'ruleset_collection_ids': this.rulesetCollectionIds || "0",
            'timestamp': new Date().getTime()
        };

        //Populate the optional Flex fields if the form model has matching fields.
        for(var i = 0; i < this.optional_fields.length; i++) {
            var name = this.optional_fields[i];
            var value = data[name];
            if(!_.isUndefined(value) && !_.isNull(value)) {
                flex[name] = value;
            }
        }

        this.dataSet = data;

        this.set({
            'flex': flex,
            'files': data.file || this.get('files')
        });
    },

    setImageDimensions: function(width, height) {
        var imageActions = this.get('imageActions');
        //adjust the resize height and width
        if(width > 1920 || height >1080) {
            if(width>height) {
                height = Math.floor(height * (1920/width));
                width = 1920;
            } else if(height>width) {
                width = Math.floor(width * (1080/height));
                height = 1080;
            } else {
                //image is a square
                width = 1080;
                height = 1080;
            }
        }

        imageActions[0].output_details[0].size.width = width;
        imageActions[0].output_details[0].size.height = height;
        this.set('imageActions',imageActions);
    },

    tailorUpload: function () {

        if (!this.resumable_upload) {
            this.makeUploadModel('single');
            return;
        }
        this.detectionForUploadModel( isAndroid() ? false : window.third_party_cookies_enabled_ts);
    }
});
