'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import modelCms from 'tscom-cms';

export default modelCms.extend({
	defaults: _.extend(modelCms.prototype.defaults, {
		/**
		 * system clock of user
		 */
		localTime: new Date().getTime()
	}),
	/**
	 * @return unix timestamp in ms (server timestamp plus time elapsed since the last request to the server)
	 */
	getServerTime: function () {
		console.log('getServerTime', 'ts', (this.get('ts')*1000), 'elapsed', this.getElapsedTime(), (this.get('ts')*1000) + this.getElapsedTime());
		return (this.get('ts')) + this.getElapsedTime();
	},
	/**
	 * @return ms time elapsed since the last request to the server
	 */	
	getElapsedTime: function () {
		return (new Date().getTime() - this.get('localTime'));
	}
})