'use strict';

import _ from 'lodash';
import FacebookModel from 'tscom-facebook';

export default FacebookModel.extend({
	defaults: {
        appId: null,
        /**
         * https://developers.facebook.com/docs/reference/login/
         * Scope parameter can be passed along with the function call.
         * It is a comma separated list of Permissions to request from the person using the app.
         */
        scope: 'email',
        /**
         * App's Name Space
         *      name space is used for Open Graph Object's og:type tag
         *      <meta property="og:type" content="namespace:object-type" />
         */
        namespace: null,

        // Response Payloads
        scriptLoaded: false,
        status: null,
        authResponse: null,
        shareResponse: null,

        // data from Graph API
        user: null,
        videos: null
    },
	/**
	 * updated to 2.5
	 */
	loadedScript: function () {
        /**
         * After loading the JavaScript SDK, call FB.init to initialize the SDK with your app ID.
         * This will let you make calls against the Facebook API.
         */
        FB.init({
            'appId': this.get('appId'),      // App ID from the app dashboard
            'status': true,                  // Check Facebook Login status
            'version': 'v3.2'
        });
        this.set('scriptLoaded', true);
        /**
         * Check User Login Status when they open this app the first time
         */
    },

    /**
     * Gets user uplaoded videos
     */
    getUploadedVideos: function (callback) {
        FB.api('/me/videos/uploaded?fields=picture,description,thumbnails,length', 'GET', _.bind(function (response) {
        	this.set({'videos': response});
            callback(response);
        }, this));
    },

	/**
	 *	added in check for video permissions (user_videos)
	 *  might not be needed, user_videos might be set by default
	 */
	isConnected: function (callback) {
        console.log('isConnected', callback);
        if (typeof callback !== "function") return;

        if (this.get('status') === 'connected' && this.get('user')) {
            callback(true);
        } else {
            this.set({status: null}, {silent: true});
            this.once("change:status", _.bind(function (model, value) {
                console.log('isConnected listener value', value, 'status:', this.get('status'), 'authresponse:', this.get('authResponse'));
                if (this.get('status') === "connected") callback(true);
                else callback(false);
            }, this));
            // make sure the SDK has loaded and the FB object is defined
            if (this.get('scriptLoaded') === true) {
                this.login();
            } else {
                // once we know the SDK has loaded we can init the Router
                this.once('change:scriptLoaded', _.bind(function () {
                    this.login();
                }, this));
            }
        }
    },
    /**
     * If this model finds out that user logged out or switch account, it will reset all the required data
     */
    clearUserData: function () {
        this.set('user', null);
        this.set('videos', null);
    }

});
