module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"top <%= (buttons.twitter.display === \"false\" && buttons.facebook.display === \"false\") ? \"full\" : \"not-full\" %>\"> <div class=\"logo <%= (logo_image.length > 0 || caption.length === 0) %> <%= (caption.length > 0) ? \"caption-outer\" : \"\" %>\"> <% if(logo_image.length > 0) { %> <div class=\"image-container\"><div class=\"image-inside-container\"><img class=\"vu-logo\" src=\"<%= logo_image %>\"></div></div> <% } %> <% if(caption.length > 0 && logo_image.length === 0) { %> <div class=\"caption-container\"><div class=\"caption-inside-container\"><h2><%= caption %></h2></div></div> <% } %> </div> <% if(buttons.twitter.display === \"true\" || buttons.facebook.display === \"true\") { %> <div class=\"social-media\"> <% if(buttons.twitter.display === \"true\") { %> <img class=\"share-twitter\" src=\"../images/twitter.svg\"> <% } if(buttons.facebook.display === \"true\") { %> <img class=\"share-facebook\" src=\"../images/facebook.svg\"> <% } %> </div> <% } %> </div> <% if ( displaySubmit === 'true' && displayGallery === 'true' ) { %> <nav> <ul> <li class=\"li-submit selected\"> <% if(navigation_bar.display_nav_icons === 'true') { %> <img src=\"images/camera-icon.svg\" class=\"svg submit-svg\"> <% } %> <span href=\"#\" class=\"create-nav\"><%= navigation_bar.submit_text %></span> </li> <li class=\"li-gallery <%= typeof galleryButtonState !== 'undefined' && galleryButtonState === 'false'? 'invisible' : ''%>\"> <% if(navigation_bar.display_nav_icons === 'true') { %> <img src=\"images/gallery-icon.svg\" class=\"svg gallery-svg\"> <% } %> <span class=\"gallery-nav\"><%= navigation_bar.gallery_text %></span> </li> </ul> </nav> <% } %>"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"top ")
    ; __append(escapeFn( (buttons.twitter.display === "false" && buttons.facebook.display === "false") ? "full" : "not-full" ))
    ; __append("\"> <div class=\"logo ")
    ; __append(escapeFn( (logo_image.length > 0 || caption.length === 0) ))
    ; __append(" ")
    ; __append(escapeFn( (caption.length > 0) ? "caption-outer" : "" ))
    ; __append("\"> ")
    ;  if(logo_image.length > 0) { 
    ; __append(" <div class=\"image-container\"><div class=\"image-inside-container\"><img class=\"vu-logo\" src=\"")
    ; __append(escapeFn( logo_image ))
    ; __append("\"></div></div> ")
    ;  } 
    ; __append(" ")
    ;  if(caption.length > 0 && logo_image.length === 0) { 
    ; __append(" <div class=\"caption-container\"><div class=\"caption-inside-container\"><h2>")
    ; __append(escapeFn( caption ))
    ; __append("</h2></div></div> ")
    ;  } 
    ; __append(" </div> ")
    ;  if(buttons.twitter.display === "true" || buttons.facebook.display === "true") { 
    ; __append(" <div class=\"social-media\"> ")
    ;  if(buttons.twitter.display === "true") { 
    ; __append(" <img class=\"share-twitter\" src=\"../images/twitter.svg\"> ")
    ;  } if(buttons.facebook.display === "true") { 
    ; __append(" <img class=\"share-facebook\" src=\"../images/facebook.svg\"> ")
    ;  } 
    ; __append(" </div> ")
    ;  } 
    ; __append(" </div> ")
    ;  if ( displaySubmit === 'true' && displayGallery === 'true' ) { 
    ; __append(" <nav> <ul> <li class=\"li-submit selected\"> ")
    ;  if(navigation_bar.display_nav_icons === 'true') { 
    ; __append(" <img src=\"images/camera-icon.svg\" class=\"svg submit-svg\"> ")
    ;  } 
    ; __append(" <span href=\"#\" class=\"create-nav\">")
    ; __append(escapeFn( navigation_bar.submit_text ))
    ; __append("</span> </li> <li class=\"li-gallery ")
    ; __append(escapeFn( typeof galleryButtonState !== 'undefined' && galleryButtonState === 'false'? 'invisible' : ''))
    ; __append("\"> ")
    ;  if(navigation_bar.display_nav_icons === 'true') { 
    ; __append(" <img src=\"images/gallery-icon.svg\" class=\"svg gallery-svg\"> ")
    ;  } 
    ; __append(" <span class=\"gallery-nav\">")
    ; __append(escapeFn( navigation_bar.gallery_text ))
    ; __append("</span> </li> </ul> </nav> ")
    ;  } 
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}