import ConnectModel from 'tscom-connect';

var BASE64 = require('crypto-js/enc-base64.js');
var SHA256 = require('crypto-js/hmac-sha256.js');

export default ConnectModel.extend({

    // use s2 endpoint by default
    apiUrl: 'https://voteapi.votenow.tv/s2/vote',

    // SHA256 hashing algo for compatibility with s2 endpoint
    qspToHash: function(data) {
        var secret = this.version_id + this.versionCheck;
        var hash = SHA256(data, secret);
        return BASE64.stringify(hash);
    }

});
