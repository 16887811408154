'use strict';
import _ from "lodash";
import Backbone from "backbone";
import template from './../templates/media.ejs'; 
import videojs from 'video.js';

export default Backbone.View.extend({
    template: template,
    className: 'view-media',
    events: {
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
        this.uid = options.uid;
        this.styleID = this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
        this.$el.addClass(this.styleID);
    },
    /**
     *
     */
    render: function () {
        this.model.styleID = this.styleID;
        
        this.$el.html(this.template(this.model));


        return this;
    },
    renderedToDOM: function() {
        if(this.model.video && !/(iframe)/.test(this.model.video)){
            this.videoJS = videojs("video-"+ this.styleID, { fluid: true, nativeControlsForTouch: true }, function(){
                // Player (this) is initialized and ready.
            });
        } else if (this.model.video && /(iframe)/.test(this.model.video)){
            this.$el.addClass('iframe');
        }
    },
    remove: function() {
        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.off();
        if(this.videoJS) {
            this.videoJS.dispose();
        }
        Backbone.View.prototype.remove.call(this);
    }
});