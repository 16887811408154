'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import CSSView from './css';

import template from './../templates/modal.ejs';
import templateCss from './../templates/modal.css.ejs';

export default Backbone.View.extend({

    template: template,
    tagName: 'div',
    className: 'view-modal',
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');

        try {
            if (_.isUndefined(options.controller)) {
                throw Error('The controller is a dependency. Please inject the controller into the view.');
            }
        } catch (e) {
            console.log(e);
        }
        this.styleID = this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
        this.$el.addClass(this.styleID);
        this.controller = options.controller;
        this.globalStyles = options.globalStyles;
        this.uid = options.uid;
    },
    /**
     *
     */
    render: function () {
        if(this.CSSView) {
                this.CSSView.remove();
        }

        var cssModel = _.extend( {}, this.globalStyles, this.controller.Models.Cms.get( "text" )[ "views_error" ][ "customizations" ] || {} );
        
        this.CSSView = new CSSView({
                model: cssModel,
                namespace: '#' + 'TelescopeWidgetVideoUploaderModal' + ' .view-modal.' + this.styleID,
                template: templateCss
        });

        this.$el.html(this.template());
        Backbone.$('head').append(this.CSSView.render().$el);
        this.$('.modal-content').append((_.isObject(this.model.view) ? this.model.view.render().$el : this.model.view));
        return this;
    }

});