'use strict';

import Backbone from 'backbone';
import _ from 'lodash';
import $ from 'jquery';
import template from './../templates/header.ejs'; 
import templateCss from './../templates/header.css.ejs'; 
import CSSView from './css';

export default Backbone.View.extend({
    template: template,
    className: 'header',
    events: {
        'click .share-facebook': 'shareFb',
        'click .share-twitter': 'shareTwitter',
        'click .li-submit': 'submitPage',
        'click .li-gallery': 'galleryPage'
    },
    /**
     *
     */
    initialize: function(options) {
    	_.bindAll(this, 'render');
    	this.uid = options.uid;
    	this.styleID =	this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
    	this.$el.addClass(this.styleID);

        this.displayGallery = options.displayGallery;
        this.displaySubmit = options.displaySubmit;
    },

    /**
     *
     */
    render: function() {
        if(this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
                model: this.model['customizations'],
                namespace: '#' + this.uid + ' header .header.' + this.styleID,
                template: templateCss
        });
        
        Backbone.$('head').append(this.CSSView.render().$el);

        this.$el.html( this.template( _.extend( 
            {} , 
            this.model , 
            {
                displaySubmit: this.displaySubmit,
                displayGallery: this.displayGallery
            } ) ) );

        return this;
    },

    shareFb: function(e) {
        this.trigger("share:facebook", {});
    },
    shareTwitter: function(e) {
        this.trigger("share:twitter", {});
    },
    /**
     *
     */
    facebookShareDialog: function() {
        var data = this.model.social_sharing.facebook;

        var params = {
            name: data.name,
            appId: data.app_id,
            description: data.description,
            caption: data.caption,
            link: data.link,
            display: 'popup',
            image: data.picture
        };

        var url = window.location.href.split('?')[0];
        url = url + "share/?" + $.param(params);
        this.trigger("fbShare", {url: url});
    },

    submitPage: function(e) {
        this.$('nav ul li').removeClass('selected');
        this.$('.li-submit').addClass('selected');
        this.trigger("navigateSubmit", {});
    },

    galleryPage: function(e) {
        this.$('nav ul li').removeClass('selected');
        this.$('.li-gallery').addClass('selected');
        this.trigger("navigateGallery", {});
    },
    remove: function() {
        this.model = null;
        this.uid = null;
        this.styleID = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});