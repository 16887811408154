module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"container\"> <div class=\"confirmation\"> <h1> <%= headline1 %> </h1> <% if( image.length || video_embed_url.length ){ %> <div class=\"optional-img-video\"></div> <% } %> <div class=\"links\"> <% if ( home_link_text.length ){ %> <a href=\"#\" class=\"restart\"><%= home_link_text %></a> <% } %> <% if ( gallery_link_text.length ){ %> <br> <a href=\"#\" class=\"gallery\"><%= gallery_link_text %></a> <% } %> </div> </div> </div>"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"container\"> <div class=\"confirmation\"> <h1> ")
    ; __append(escapeFn( headline1 ))
    ; __append(" </h1> ")
    ;  if( image.length || video_embed_url.length ){ 
    ; __append(" <div class=\"optional-img-video\"></div> ")
    ;  } 
    ; __append(" <div class=\"links\"> ")
    ;  if ( home_link_text.length ){ 
    ; __append(" <a href=\"#\" class=\"restart\">")
    ; __append(escapeFn( home_link_text ))
    ; __append("</a> ")
    ;  } 
    ; __append(" ")
    ;  if ( gallery_link_text.length ){ 
    ; __append(" <br> <a href=\"#\" class=\"gallery\">")
    ; __append(escapeFn( gallery_link_text ))
    ; __append("</a> ")
    ;  } 
    ; __append(" </div> </div> </div>")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}