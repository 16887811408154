'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import template from './../templates/galleryitem.ejs';

export default Backbone.View.extend({
    template: template,
    className: 'gallery-tile',
    events: {
//        'click .video-thumb': 'chooseVideo'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function () {
        this.$el.html(this.template(this.model));
        return this;
    },
    renderedToDOM: function() {
//        var images = $(".video-img");//this.el.getElementsByClassName('video-img');
//        SmartCrop.crop(images[0], {width: 140, height: 80}, function(result){
//            console.log(result, "smartcrop");
//        });
        console.log('renderedToDOM');
        this.animateTiles(500);
    },
    chooseVideo: function(e) {
        console.log('chooseVideo', e.target.getAttribute('data-position'));
        this.trigger('chooseVideo', {'position': e.target.getAttribute('data-position')});
    },
    animateTiles: function(speed,callback){

      var hiddenTiles = this.$('.invisible');
      if(hiddenTiles.length > 0){
        
        for(var i=0; i<hiddenTiles.length; i++) {
          _.delay(_.bind(function(k){
            $(hiddenTiles[k]).animate({ opacity: 1 },500).addClass('scale-in').removeClass('invisible');
            if(hiddenTiles.length === k+1 && callback) _.bind(callback,this); 
          },this),(i*speed/4),i);
        }

      }

    },
    newTiles: function() {
        //for now
        this.render();
        //append new tiles to the top with class invisible
        //do animate tiles
        
    },
    remove: function() {
        this.model = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});