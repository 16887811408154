export function getQueryParamByName (name) {
    var regexS = '[\\?&]' + name + '=([^&#]*)';
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results === null) {
        return '';
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}

/**
 * [dateDiffInDays description]
 * @param  {[type]} dates [description]
 * @return {[type]}       [description]
 */
export function dateDiffInDays(dates) {
    var _MS_PER_DAY = 1000 * 60 * 60 * 24,
        a = dates.a,
        b = dates.b;
    // Discard the time and time-zone information.
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function cleanObject(obj) {
    Object.keys(obj).forEach(function (key) {
        if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
        else if (obj[key] === null || obj[key] === '') delete obj[key]
    });
}

export function isAndroid () {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("android") > -1;
}
