'use strict';

import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
Backbone.$ = $;

import router from './routes/router';
import ModelCms from './models/cms';
import ModelConnect from './models/connect';
import ModelGeo from 'tscom-geo';
import ModelFacebook from './models/facebook';
import ModelFlex from './models/flex';
import ModelGoogle from 'tscom-google-analytics';
import ModelModeration from './models/moderation';
import ModelStorage from './models/storage';

import { getQueryParamByName } from './util/helpers';


/**
 *
 * @param options - {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-datetime' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // string (absolute path)
 * hashState: false // boolean
 * endpoints: {cms: '//widgetstate.votenow.tv/v1/state'} // object
 * }
 */
export default function TelescopeWidget (options) {

    options = options || {};
    options.widget_id = options.widget_id || 'a14080c157e17991';
    // options.widget_id = "data/data_tsn.json";

    options.hashState = options.hashState || false;
    options.endpoints = _.extend({
        cms: '//widgetstate.votenow.tv/v1/state',
        moderation: '//secureapi-cloud.votenow.tv/moderation/getMessages',
        platform: 'https://voteapi-cloud.telescope.tv/platform',
        connect: 'https://connect-cloud-prod.telescope.tv/',
        tailor: 'https://tailor.telescope.tv',
        voteApi: 'https://voteapi-cloud.telescope.tv/api'
    }, ($.type(options.endpoints) === 'object'? options.endpoints : {}));


    var kickoff = $.Deferred();

    var controller = {
        Models: {},
        Collections: {},
        Views: {},
        Routers: {},
        Endpoints: {},

        /**
         * the following set up functions will be called in a then chain with Deferred Obj
         */
        loadEnvironmentConfig: function(){
            //Detect the current environment.
            this.env = getQueryParamByName('env');

            var env = this.env ? '?env=' + this.env : '';

            return $.ajax('/config/getConfig.php' + env);
        },
        updateEndpoints: function(response, status){
            options.endpoints = _.extend(options.endpoints, response);
            this.Endpoints = options.endpoints;

            //this will kick-off the initialization chain
            kickoff.resolve();
        },
        useDefaultEndpoints: function(){
            this.Endpoints = options.endpoints;
            //this will kick-off the initialization chain
            kickoff.resolve();

        },
        setUpCms: function(){
            this.Models.Cms = new ModelCms({
                wid: options.widget_id
                , apiUrl: options.endpoints.cms
            }, {controller: this});

            return this.Models.Cms.fetch();
        },
        setUpGeo: function(){
            // Akamai Geo Location
            this.Models.Geo = new ModelGeo({
                countries: this.Models.Cms.get('settings').countries
            });

            return this.Models.Geo.fetch();

        },
        setUpConnect: function() {
            if(typeof this.Models.Cms.get('settings').get_apiKey !== 'undefined'
                && this.Models.Cms.get('settings').get_apiKey !== '') {
                this.Models.Connect = new ModelConnect({
                    apiKey: this.Models.Cms.get('settings').get_apiKey,
                    apiUrl: options.endpoints.connect,
                    country: this.Models.Geo.get('geoheaders').country,
                    state: this.Models.Geo.get('geoheaders').region
                },{
                    version_id: this.Models.Cms.get('settings').get_versionId,
                    secret_key: this.Models.Cms.get('settings').get_secretKey,
                    controller: this
                });
                this.Models.Connect.versionCheck = "3radndyttalo23r23re0";
            } else {
                this.Models.Connect = null;
            }
        },
        setUpFlex: function(){
            this.Models.Flex = new ModelFlex({
                    apiKey : this.Models.Cms.get('settings').apiKey,
                    topics: this.Models.Cms.get('text').moderation_settings.topic_id_primary,
                    ruleset_collection_ids: this.Models.Cms.get('text').moderation_settings.ruleset_collection_id
                },
                {
                    version_id : this.Models.Cms.get('settings').versionId,
                    geoCountry: this.Models.Geo.get('geoheaders').country,
                    geoState: this.Models.Geo.get('geoheaders').region,
                    apiUrl: options.endpoints.flex_url,
                    apiUri: options.endpoints.flex_uri
                }
            );
        },
        setUpStorage: function(){
            this.Models.Storage = new ModelStorage({});
        },
        setUpFacebook: function(){
            this.Models.Facebook = new ModelFacebook({
                appId: this.Models.Cms.get('social').fb.id || '444172322357134',
                scope: this.Models.Cms.get('social').fb.scope || '',
                namespace: this.Models.Cms.get('social').fb.namespace || ''
            }, {
                controller: this
            });

        },
        setUpModeration: function(){

            var settings = this.Models.Cms.get('text').moderation_settings;
            this.Models.Moderation = new ModelModeration({
                topic_id: settings.topic_id_primary + (settings.moderation_folder.length > 0 ? ":" + settings.moderation_folder : ""),
                contains: '',
                offset: settings.messages_per_query,
                updateFrequency: settings.polling_frequency_in_seconds
            }, {
                apiUrl: options.endpoints.moderation
            });

        },
        setUpGoogle: function(){
            this.Models.Google = new ModelGoogle({}, {
                controller : this
                ,ga_id : this.Models.Cms.get('settings').google_analytics
                ,ga_domain : 'auto'
            });

        },
        setUpRouter: function(){
            this.Routers.Router = new (router({hashState: options.hashState}))({
                el: options.container,
                modal: options.modal,
                uid: options.uniqueId,
                controller: this
            });
        },
        afterSetUp: function(){
            Backbone.history.start();
        }
    };

    _.extend(controller, Backbone.Events);

    controller.loadEnvironmentConfig()
        .then(
            controller.updateEndpoints.bind(controller),
            controller.useDefaultEndpoints.bind(controller)
        )


    kickoff
        .then(controller.setUpCms.bind(controller))
        .then(controller.setUpGeo.bind(controller))
        .then(controller.setUpFlex.bind(controller))
        .then(controller.setUpStorage.bind(controller))
        .then(controller.setUpFacebook.bind(controller))
        .then(controller.setUpModeration.bind(controller))
        .then(controller.setUpConnect.bind(controller))
        .then(controller.setUpGoogle.bind(controller))
        .then(controller.setUpRouter.bind(controller))
        .then(controller.afterSetUp.bind(controller))


    return this;

};
