'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";

import ViewError from './../views/error';
import ViewLoading from './../views/loading';
import ViewSubmit from './../views/submit';
import ViewModal from './../views/modal';
import ViewPage from './../views/page';
import ViewHeader from './../views/header';
import ViewCss from './../views/css';
import ViewGallery from './../views/gallery';
import ViewSingleVideo from './../views/singlevideo';
import ViewUserForm from './../views/userform';
import ViewConfirmation from './../views/confirmation';
import ViewMedia from './../views/media';
import ModelForm from 'uploaderform/app/scripts/models/form.validation';

import TLDRC from 'tldr-child';
import PubSub from '../PubSub';


import { getQueryParamByName } from '../util/helpers';

export default function routerConstructor (options) {

    var hashState = options.hashState,
    Router = Backbone.Router

    return Router.extend({
        controller: null,
        currentView: null,
        currentModal: null,
        routes: {
            ''              : 'submit',
            'home'          : 'submit',
            'submit'        : 'submit',
            'error/:name'   : 'error',
            'loading/:name' : 'loading',
            'confirmation'  : 'confirmation',
            'userform'      : 'userForm'
        },
        history: [],
        /**
         *
         */
        initialize: function (options) {
            if (_.isUndefined(options)) {
                return;
            }
            this.uid = options.uid;

            this.controller = options.controller;
            this.instanceID = $(options.el || document.body).attr('id');

            this.on('route', _.bind(this._saveHistory, this));

            // VU-96: Add options to toggle submit/gallery
            this.displayGallery = this.controller.Models.Cms.get( 'text' )[ 'view_gallery' ][ 'display' ] || 'true';
            this.displayGalleryOnClosedWindow = this.controller.Models.Cms.get( 'text' )[ 'view_gallery' ][ 'display_on_closed_window' ] || 'false';
            this.displaySubmit = this.controller.Models.Cms.get( 'text' )[ 'view_submit' ][ 'display' ] || 'true';

            var galleryButtonState = this.controller.Models.Cms.get("text").view_submit.customizations.show_gallery;
            var wid = this.controller.Models.Cms.get('wid');

            this.header = new ViewHeader({
              model: _.extend(this.controller.Models.Cms.get('text').view_header, {
                galleryButtonState: galleryButtonState,
              }),
              displaySubmit: this.displaySubmit,
              displayGallery: this.displayGallery,
              uid: this.uid
            });

            this.page = new ViewPage({
                headerView: this.header,
                uid: this.uid
            }).render();


            this.$body = $(options.el || document.body)
            this.$modalScope = $(options.modal || this.$body)
            this.$app = this.page.$('div[role="main"]').addClass(wid);
            this.$header = this.page.$('header');
            this.$footer = this.page.$('footer');
            this.$modal = $('<div class="modal"'+(   (_.isEqual(options.container, options.modal)? '' : ' id="'+options.uid+'Modal"' )   )+' />');

            console.log('Router initialize', this.instanceID);

            this.$body.html(this.page.$el.addClass((top === self ? 'frame-false' : 'frame-true')));
            this.$modalScope.append(this.$modal);

            this.pymChildId = getQueryParamByName('childId') || "";

            if( top !== self ){
                this.pym = new TLDRC.Child({polling: 500});
            } else {
                // VU-166: Extend background to full height of app when not iframed
                [ document.body.parentNode, document.body, this.$body[ 0 ], this.page.$el[ 0 ] ].forEach( function( el ){
                    el.style.height = "100%";
                } );

                // remove overflow hidden from page
                this.page.$el.css( "overflow", "auto" );
            }

            this.css();
            this.listenTo(this.controller.Models.Cms, 'change:text', _.bind(this.css, this));
            this.listenTo(this.header, 'navigateSubmit', _.bind(this.submit, this));
            this.listenTo(this.header, 'navigateGallery', _.bind(this.gallery, this));
            this.listenTo(this.header, 'share:facebook', _.bind(this.fbShare, this));
            this.listenTo(this.header, 'share:twitter', _.bind(this.twitterShare, this));
            this.listenTo(PubSub, "navigate:gallery", _.bind(this.gallery, this));

            this.listenTo(PubSub, 'age-gate', _.bind(function() {
                this.stopListening(this.currentView);
                this.error('age-gate');
            }, this));

            window.addEventListener( "resize", function(){
                PubSub.trigger( "windowResize" );
            });
            this.firstRender = true;

            this.getUserIp();
            $(document).on('click', 'input[type="button"],input[type="checkbox"],input[type="submit"],input[type="file"],select,button,a', _.bind(this.handleViewEvent, this));
        },
        /**
         *
         */
        setCurrentView: function (view, route) {
            this.closeCurrentModal();
            this.$app.html(view.render().$el);

            var bodyContext = 'body-' + view.className;
            this.$app.alterClass('body-*', bodyContext);

            // remove old view
            if (this.currentView !== null) {
                this.currentView.remove();
                this.currentView = null;
            }
            // store current view
            this.currentView = view;

            if (typeof view.renderedToDOM === 'function') {
                view.renderedToDOM();
            }
            this.convertImgToSvg();

            this._handlePageView(route);

            if( this.pym ){
                this.pym.sendHeight();
            }

            console.log('MPH-79: setCurrentView this.firstRender: ', this.firstRender);

            if(!this.firstRender) {
                this.scrollToTop();
            }

            this.firstRender = false;
        },
        /**
         *
         */
        setCurrentModal: function (view) {
            var modalContext = 'modal-has-' + view.className;
            var modal = new ViewModal({
                model: {view: view},
                controller: this.controller,
                globalStyles: this.controller.Models.Cms.get('text')['global_customizations'],
                uid: this.uid
            });
            this.$modalScope.alterClass('modal-has-*', modalContext);
            this.$modal.html(modal.render().$el);



            // remove old modal
            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
            }
            // store current modal
            this.currentModal = this.controller.Views.Modal = modal;
            if (typeof view.renderedToDOM === 'function') {
                view.renderedToDOM();
            }
            this.convertImgToSvg();

            if( this.pym ){
                this.pym.sendHeight();
            }

        },
        /**
         *
         */
        closedCurrentModal: function () {
            console.log('MODAL HIDDEN!!');
            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
                this.$modalScope.alterClass('modal-has-*', '');
            }
        },
        /**
         *
         */
        closeCurrentModal: function () {
            console.log('CLOSE CURRENT MODAL');

//      this.$modal.modal('hide');
            this.closedCurrentModal();

        },

        getUserIp: function(){
            // add in a cachebuster for the ip fetching
            var cacheBuster = Math.floor(Math.random() * 10000) + (new Date()).getTime();

            $.ajax({
                url: '/getClientIp.php?' + cacheBuster,
                type: 'GET',
                dataType: "json"
            }).done(function(data){
                this.userIP = data['ip-user'];
                console.log('MPH-33: getUserIP: ', this.userIP, data['ip-user'], data);
            }.bind(this));
        },
        /**
         *
         */
        submit: function () {
            console.log('ROUTE SUBMIT', this.instanceID)
            if( !this._isOpen( 'submit' ) ){
                return;
            }
            if( this.displaySubmit !== 'true' ){
                this.gallery();
                return;
            }
            var view = new ViewSubmit({
                model: this.controller.Models.Cms.get('text')['view_submit'],
                videoFormats: this.controller.Models.Cms.get('text')['moderation_settings'].video_formats,
                imageFormats: this.controller.Models.Cms.get('text')['moderation_settings'].image_formats,
                uid: this.uid,
                tailorOptions: this.getTailorOptions(),
                errorCodes: this.controller.Models.Cms.get('text')['errors'],
                globalStyles: this.controller.Models.Cms.get('text')['global_customizations']
            });
            this.setCurrentView(view, "LandingPage");
            this.listenTo(view, 'fileUploaded', _.bind(function(data){
                this.stopListening(view);

                this.userForm(data);
            }, this));
        },
        userForm: function(firstData) {
            console.log('ROUTE USERFORM', this.instanceID)
            if(!this._isOpen('userform')){
                return;
            }
            var model = new ModelForm({}, { fieldData: this.controller.Models.Cms.get('data')});
            var view = new ViewUserForm({
                model: model,
                copy: _.extend(this.controller.Models.Cms.get('text')['view_user_form'],
                        {
                            'fields': model.getFieldData()
                        }
                    ),
                storage: this.controller.Models.Storage,
                uid: this.uid,
                cms: this.controller.Models.Cms,
                scrollToFramePosition: this.scrollToFramePosition.bind( this ),
                isIframed: Boolean( this.pym )
            });
            this.setCurrentView(view, "DetailsPage");

            this.listenTo(view, 'cancel', _.bind(function(data){
                this.stopListening(view);
                this.submit();
            }, this));

            this.listenTo(view, 'age-gate', _.bind(function(data) {
                this.stopListening(view);
                this.error('age-gate');
            }, this));

            this.listenTo(view, 'formSubmit', _.bind(function(data){
                this.stopListening(view);
                if(this.controller.Models.Connect && !_.isNull(this.controller.Models.Connect)) {
                    // do a get call before submitting
                    // do get here to check email
                    this.controller.Models.Connect.set({
                        // country: this.controller.Models.Geo.get('geoheaders').country,
                        // state: this.controller.Models.Geo.get('geoheaders').region,
                        email: data.email || ""
                    });
                    this.controller.Models.Connect.fetch()
                    .done(function(response) {
                        console.log(response, "getVotes");
                        switch (response.response_code) {
                            case "20":
                                this._submitForm(data, firstData);
                                break;
                            case "21":
                                this.error('limit');

                                break;
                            case "40":
                                this.error('window');

                                break;
                            default:
                                //general error
                                this.error('');
                                break;
                        }
                    }.bind(this))
                    .fail(function(error) {
                        console.log('getVotes error:', error);
                    }.bind(this));
                } else {
                    this._submitForm(data, firstData);
                }

            }, this));

            this.scrollToTop();
        },
        _submitForm: function(data, firstData) {
            if(firstData.fileData.file) {

                console.log('MPH-33: _submitForm: ', this, this.userIP);
                this.completeTailor(_.extend(data, {
                    'message': firstData.fileData.message,
                    'name': firstData.fileData.name,
                    'cloudfront_url': this.controller.Models.Cms.get("settings").cloudfront_url,
                    'ip-user': this.userIP
                }), firstData.tailorModel );
            } else {
                this.submitToFlex(_.extend(data, firstData.fileData));
            }
        },
        confirmation: function() {
            console.log('ROUTE confirmation', this.instanceID)
            if(!this._isOpen('confirmation')){
                return;
            }
            var mediaView = new ViewMedia({
                model: {},
                uid: this.uid,
                mediaView: mediaView
            });

            var galleryButtonState = this.controller.Models.Cms.get("text").view_submit.customizations.show_gallery;
            var model = _.extend({}, this.controller.Models.Cms.get('text')['view_confirmation'], { galleryButtonState: galleryButtonState });

            var view = new ViewConfirmation({
                model: model,
                uid: this.uid,
                mediaView: mediaView
            });

            this.setCurrentView(view, "ConfirmationPage");

            this.listenTo(view, 'navigateSubmit', _.bind(function(){
                this.stopListening(view);
                this.header.submitPage();}, this));
            this.listenTo(view, 'navigateGallery', _.bind(function(){
                this.stopListening(view);
                this.header.galleryPage();}, this));
        },
        gallery: function() {
            console.log('ROUTE GALLERY', this.instanceID)
            if( this.displayGalleryOnClosedWindow !== 'true' || this.displayGallery !== 'true' ){
                if( !this._isOpen('gallery') ){
                    return;
                }
            }

            this.controller.Models.Moderation.fetchNewMessages()
                .then(function () {

                    var view = new ViewGallery({
                        model: this.controller.Models.Cms.get('text')['view_gallery'],
                        moderation: this.controller.Models.Moderation,
                        uid: this.uid
                    });

                    this.setCurrentView(view, "GalleryPage");
                    this.listenTo(view, 'chooseVideo', _.bind(function (data) {
                        this.stopListening(view);
                        this.singleVideo(data.position);
                    }, this));

                }.bind(this))
                .fail(function () {
                    this.error('generic');
                }.bind(this))
        },
        singleVideo: function(index) {
            console.log('ROUTE singleVideo', this.instanceID)
            if(!this._isOpen('singleVideo')){
                return;
            }
            if (!this.controller.Models.Moderation.get('initialized')) {
                this.controller.Models.Moderation.once('change:initialized',
                    _.bind(function () {
                        this.singleVideo(index);
                }, this));
                return;
            } else {
             var mediaView = new ViewMedia({
                model: {},
                uid: this.uid
            });

            var view = new ViewSingleVideo({
                    model: this.controller.Models.Cms.get('text')['view_single'],
                    moderation: this.controller.Models.Moderation,
                    position: index,
                    uid: this.uid,
                    mediaView: mediaView
                });
                this.setCurrentView(view, "MediaPage");
                this.listenTo(view, 'changeVideo', _.bind(function(data){
                    this.stopListening(view);
                    this.handleViewEvent("",data.tracking);
                    this.singleVideo(data.position);
                },this));
                this.listenTo(view, 'share:facebook', _.bind(this.fbShare, this));
                this.listenTo(view, 'share:twitter', _.bind(this.twitterShare, this));

            }
        },
        /**
         *
         */
        error: function (name) {
            console.log('ROUTE ERROR', name, this.instanceID)
            var data = this.controller.Models.Cms.get('text')['views_error'][name];
            data = (_.isObject(data) ? data : this.controller.Models.Cms.get('text')['views_error']['generic']);
            var view = new ViewError({
                model: data,
                uid: this.uid,
                name: name
            });

            if(name === "geo" || name === "window"){
                this.setCurrentView(view, "Error");
            } else {
                this.setCurrentModal(view);
            }

            this.listenTo(view, 'route', _.bind(function(data){
                this.navigate(data.path, {trigger: true});
                this.stopListening(view);
            }, this));

        },
        /**
         *
         */
        loading: function (name) {
            console.log('ROUTE LOADING', this.instanceID)
            var data = this.controller.Models.Cms.get('text')['views_loading'][name];
            data = (_.isObject(data) ? data : this.controller.Models.Cms.get('text')['views_loading']['generic']);
            var view = new ViewLoading({
                model: data,
                uid: this.uid
            });
            this.setCurrentModal(view);
        },
        completeTailor: function(data, tailorModel) {
            tailorModel.setUpData(data);
            this.listenTo(tailorModel, 'error', _.bind(function(){
                this.stopListening(tailorModel);
                this.error("upload");
            }, this));

            this.listenTo(tailorModel, 'success:flex', _.bind(function(){
                    this.listenTo(tailorModel, 'sync', _.bind(function(){
                        this.stopListening(tailorModel);
                        this.confirmation();
                }, this));
                tailorModel.tailorStart();
            }, this));
            tailorModel.save();
        },
        submitToFlex: function(data){
            this.controller.Models.Flex.setUpData(data);
            this.listenTo(this.controller.Models.Flex, 'sync', _.bind(this.onFlexSync, this));
            this.listenTo(this.controller.Models.Flex, 'error', _.bind(this.handleErrorCodes, this));
            this.loading();
            this.controller.Models.Flex.save();
        },
        onFlexSync: function(model) {
            this.stopListening(model);
            if(model.get('flex_message') || model.get('job_id') !== undefined) {
                this.confirmation();

            } else {
                this.error("upload", model);
            }
        },

        handleErrorCodes: function (model) {
            this.stopListening(model);
            this.error("upload");
        },
        /**
         *
         */
        css: function() {
            if(this.CSSView) {
                this.CSSView.remove();
            }

            this.CSSView = new ViewCss({
                model: this.controller.Models.Cms.get('text')['global_customizations'],
                namespace: '#' + this.uid + ' .page-view ',
                namespaceModal: '#' + this.uid + 'Modal',
                uid: this.uid
            });
            Backbone.$('head').append(this.CSSView.render().$el);
        },
        fbShare: function(evt) {

            var data = this.controller.Models.Cms.get('social').fb.share;
            var url = data.link || window.location.origin + '/share' + window.location.search;

            this.controller.Models.Facebook.share(url);
            this.handleViewEvent('','FacebookShare');

        },
        twitterShare: function(evt) {
            window.open("https://twitter.com/intent/tweet?text="+encodeURIComponent(this.controller.Models.Cms.get("social").twitter.tweet), "Twitter", "width=550,height=420");
            this.handleViewEvent("","TwitterShare");
        },
        convertImgToSvg: function() {
            //change svg img to svg
            this.page.$('img.svg').each(function(){
                var $img = $(this);
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, else we gonna set it if we can.
                    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        /**
         *
         */
        _isOpen: function ( requestedFragment ) {
            // 1. If we are in one of the allowed regions, AND the window
            //    is open, immedately return true.  In all other cases,
            //    return false.
            // 2. If the region data has not returned yet, CHANGE the url
            //    to show loading, then once the region is available, CHANGE
            //    the url back to what it was at the time of the call
            // 3. If the region data has returned, but is not valid, CHANGE
            //    the url show out-of-geo
            // 4. If the region data has returned and is good, but the window
            //    is not open, CHANGE the url to the closed window page
            requestedFragment = requestedFragment || 'home';

            // is the user out of the geographically acceptable region
            if (_.isNull(this.controller.Models.Geo.get('inRegion'))) {
                this.controller.Models.Geo.once('change:inRegion', _.bind(function () {
                    this[requestedFragment]();
                    // this.navigate(requestedFragment, {trigger: true})
                }, this));
                this.navigate('loading/generic', {trigger: true});
                return false;
            } else if (!this.controller.Models.Geo.get('inRegion')) {
                this.navigate('error/geo', {trigger: true})
                return false;
            }

            // is the window closed?
            if( parseInt(this.controller.Models.Cms.get('settings').window_status, 10)===0 ){
                this.navigate('error/window', {trigger: true})
                return false;
            }

            return true;
        },
        /**
         *
         */
        _saveHistory: function(route, params){
            console.log('listener route '+this.instanceID, route, params)
            this.history.unshift({
                route: route
                , params: params
            });
        },
        /**
         *
         */
        _back: function () {
            if(!hashState){
                var current = this.history.shift()
                    ,previous = this.history[0]
                    ;
                this[previous.route](previous.params);
            } else {
                window.history.back()
            }
        },
        /**
         *
         * @param route
         * @private
         */
        _handlePageView: function (route){
            /* NOTE ----------------------------------------------------
                NEED TO CLEAN THIS PORTION OF THE APPLICATION UP
            ---------------------------------------------------------- */
            console.log('_handlePageView', route);
            if(route && route.indexOf('loading:generic')<0){
                this.controller.Models.Google.set({
                    eventCategory: 'page view',
                    eventAction: route,
                    eventLabel: 'page view:'+route
                });
                this.controller.Models.Google.save();
                console.log("_handlePageView:", route);
            }
        },
        /**
         *
         * @param e
         * @param eventName
         */
        handleViewEvent: function (e, eventName) {
            /* NOTE ----------------------------------------------------
                NEED TO CLEAN THIS PORTION OF THE APPLICATION UP
            ---------------------------------------------------------- */
            console.log('handleViewEvent', e, eventName)

            var category = ''
                , action = ''
                , tag = (_.isString(e)? '' : e.currentTarget.nodeName)
                , el = (_.isString(e)? '' : $(e.currentTarget))
                , data = (_.isString(eventName) && eventName.length > 0) ? eventName : (el.is('[data-track]')? el.attr('data-track') : undefined ) ;

            tag = (_.isString(e)? tag : e.currentTarget.nodeName);

            switch(tag.toLowerCase()) {
                case 'a':
                    category = 'link';
                    action = el.text() +' - '+ el.prop('href');
                    break;
                case 'button':
                    category = 'button';
                    action = el.text();
                    break;
                case 'input':
                    category = 'button';
                    action = el.val() +' - '+ el.prop('type');

                    if (el.prop('type') === 'checkbox') {
                        action = el.prop('checked') +' - '+ el.prop('type')+' - '+  el.prop('name');
                    }

                    break;
            }

            category = (_.isString(eventName)? 'button' : category );
            action = (!_.isUndefined(data)? data : action );

            console.log('handleViewEvent', 'eventCategory:', category, 'eventAction:', action)

            this.controller.Models.Google.set({
                eventCategory: category,
                eventAction: action,
                eventLabel: category+":"+action
            });
            this.controller.Models.Google.save();

        },
        /**
        * scrollToTop
        */
        scrollToTop: function() {

            this.scrollToFramePosition(0);

        },
        /**
         * scrollToFramePosition
         * @param {number} yPos
         * @return {undefined}
         */
        scrollToFramePosition: function( yPos ) {
            if ( this.pym ) {
                this.pym.sendMessage('scrollToChildPosition', {y: yPos});
            } else {
                this.page.el.scrollTop = yPos;
                window.scrollTo(0, yPos);
            }
        },
        getTailorOptions: function(){

            return ({
                apiUrl: this.controller.Endpoints.tailor,
                auth_token: this.controller.Models.Cms.get('settings').tailor_auth_token,
                geoCountry: this.controller.Models.Geo.get('country'),
                geoState: this.controller.Models.Geo.get('geoheaders').region,
                topics: this.controller.Models.Cms.get('text').moderation_settings.topic_id_primary,
                rulesetCollectionIds: this.controller.Models.Cms.get('text').moderation_settings.ruleset_collection_id
            });

        }
    });

};
