'use strict';
import _ from "lodash";
import Backbone from "backbone";
import template from '../templates/confirmation.ejs'; 
import templateCss from '../templates/confirmation.css.ejs'; 
import CSSView from './css';

export default Backbone.View.extend({
    template: template,
    className: 'view-confirmation',
    events: {
        'click .restart': 'submitPage',
        'click .gallery': 'galleryPage'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
        this.uid = options.uid;
        this.mediaView = options.mediaView;
        this.styleID = this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
        this.$el.addClass(this.styleID);
    },
    /**
     *
     */
    render: function () {
        if(this.CSSView) {
                this.CSSView.remove();
        }

        this.CSSView = new CSSView({
                model: this.model['customizations'],
                namespace: '#' + this.uid + ' .view-confirmation.' + this.styleID,
                template: templateCss
        });
        this.model.styleID = this.styleID;
        this.$el.html(this.template(this.model));

        Backbone.$('head').append(this.CSSView.render().$el);
        this.mediaView.model = {'video': this.model.video_embed_url, 'images': this.model.image};
        this.$('.optional-img-video').html(this.mediaView.render().$el);

        return this;
    },
    submitPage: function(e) {
        e.preventDefault();
        this.trigger("navigateSubmit", {});
    },
    galleryPage: function(e) {
        e.preventDefault();
        this.trigger("navigateGallery", {});
    },
    renderedToDOM: function() {
        this.mediaView.renderedToDOM();
    },
    remove: function() {
        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});