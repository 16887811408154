'use strict';

import Backbone from 'backbone';
import _ from 'lodash';
import template from './../templates/page.ejs';

export default Backbone.View.extend({

    template: template,
    className: "page-view",
    events: {},
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        this.headerView = options.headerView;
        this.uid = options.uid;
    },
    /**
     *
     */
    render: function() {
        this.$el.html(this.template());
        this.listenTo(this.header, "fbShare", _.bind(function(evt){
                this.trigger("fbShare", evt);
        }, this));
        this.$('header').append(this.headerView.render().$el);
        return this;
    },
    /**
     *
     */
    updateCSS: function(cms) {

    },
    remove: function() {
            this.headerView = null;
            this.model = null;
            this.off();
            Backbone.View.prototype.remove.call(this);
    }

});