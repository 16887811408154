'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import template from './../templates/gallery.ejs';
import templateCss from '../templates/gallery.css.ejs';
import CSSView from './css';
import ItemView from './galleryitem';

export default Backbone.View.extend({
    template: template,
    className: 'view-gallery',
    events: {
        'click .video-thumb': 'chooseVideo',
        'click .load-more': 'loadOlderContent'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
        this.uid = options.uid;
        this.moderation = options.moderation;
        this.styleID = this.className + _.uniqueId(Math.random() + "" + new Date().getTime()).replace('.', '');
        this.$el.addClass(this.styleID);

        this.listenTo(this.moderation, 'moderation:new-messages', this.newTiles.bind(this));
        this.listenTo(this.moderation, 'moderation:old-messages', this.appendTilesToBottom.bind(this));
        this.currentPosition = 0;
    },
    /**
     *
     */
    render: function () {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model['customizations'],
            namespace: '#' + this.uid + ' .view-gallery.' + this.styleID,
            template: templateCss
        });
        this.model.styleID = this.styleID;

        this.$el.html(this.template(this.model));

        Backbone.$('head').append(this.CSSView.render().$el);
        //this.$('.gallery').append(galleryItems.render().$el);

        var tiles = this.moderation.get('allMessages');

        var galleryItem;

        _.forEach(tiles, _.bind(function (tile, index) {
            this.model.tile = tile;
            this.model.position = index;
            galleryItem = new ItemView({
                model: this.model
            });
            this.currentPosition = index;
            this.$('.gallery').append(galleryItem.render().$el);
        }, this));

        return this;
    },
    renderedToDOM: function () {
        //        var images = $(".video-img");//this.el.getElementsByClassName('video-img');
        //        SmartCrop.crop(images[0], {width: 140, height: 80}, function(result){
        //            console.log(result, "smartcrop");
        //        });
        console.log('renderedToDOM');
        this.animateTiles(500);

        //check if the load-more button should be rendered
        var allMessages = this.moderation.get('allMessages');
        var maxOffset = parseInt(this.moderation.get('offset'), 10);
        var noMoreOldMessages = this.moderation.get('noMoreOldMessages');

        if (noMoreOldMessages || allMessages.length < maxOffset) {
            this.hideButton();
        }
    },
    chooseVideo: function (e) {
        console.log('chooseVideo', e.target.getAttribute('data-position'));
        this.trigger('chooseVideo', {
            'position': e.target.getAttribute('data-position')
        });
    },
    animateTiles: function (speed, callback) {

        var hiddenTiles = this.$('.invisible');
        if (hiddenTiles.length > 0) {

            for (var i = 0; i < hiddenTiles.length; i++) {
                _.delay(_.bind(function (k) {
                    $(hiddenTiles[k]).animate({
                        opacity: 1
                    }, 500).addClass('scale-in').removeClass('invisible');
                    if (hiddenTiles.length === k + 1 && callback) _.bind(callback, this);
                }, this), (i * speed / 4), i);
            }

        }

    },
    newTiles: function () {
        //append new tiles to the top with class invisible
        //do animate tiles

        var newTiles = this.moderation.get('newMessages').reverse();
        var galleryItem;
        _.forEach(newTiles, _.bind(function (tile, index) {
            this.model.tile = tile;
            this.model.position = index;

            galleryItem = new ItemView({
                model: this.model
            });

            this.$('.gallery').prepend(galleryItem.render().$el);

        }, this));

        this.resetDataPosition();
        this.animateTiles(500);


    },
    hideButton: function () {
        this.$('.load-more').hide();
    },
    appendTilesToBottom: function () {
        var oldMessages = this.moderation.get('oldMessages');
        var galleryItem;
        if (oldMessages.length < parseInt(this.moderation.get('offset'), 10)) {
            this.hideButton();
        }

        _.each(oldMessages, function (tile, index) {
            this.model.tile = tile;
            this.model.position = index;

            galleryItem = new ItemView({
                model: this.model
            });
            this.$('.gallery').append(galleryItem.render().$el);

        }.bind(this));

        this.resetDataPosition();
        this.animateTiles(500);
    },
    /**
     * reset data-position attribute so that the correct content is
     *
     * @return {[type]} [description]
     */
    resetDataPosition: function () {

        var arr = this.$('.video');


        _.forEach(arr, function (el, index) {
            el.setAttribute("data-position", index);
            el.firstChild.setAttribute("data-position", index);
        });

    },
    loadOlderContent: function () {
        this.moderation.fetchOlderMessages();
    },

    remove: function () {

        this.moderation.stopPolling();

        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    },
    handleMessageRemoval: function () {
        this.render();
        this.renderedToDOM();
    }
});
