'use strict';
import _ from "lodash";
import $ from "jquery";
import Backbone from "backbone";
import template from './../templates/singlevideo.ejs';
import templateCss from './../templates/singlevideo.css.ejs';
import CSSView from './css';
import constants from '../util/constants';

export default Backbone.View.extend({
    template: template,
    className: 'view-single-video',
    events: {
        'click .next': 'nextVideo',
        'click .prev': 'prevVideo',
        'click .facebook': 'shareFb',
        'click .twitter': 'shareTwitter',
        'keydown': 'checkKeydown'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render', 'checkKeydown', 'nextVideo', 'prevVideo');
        this.uid = options.uid;
        this.styleID = this.className + _.uniqueId(Math.random()+""+new Date().getTime()).replace('.','');
        this.$el.addClass(this.styleID);
        this.moderation = options.moderation;
        this.mediaView = options.mediaView;
        this.position = parseInt(options.position);
    },
    /**
     *
     */
    render: function () {
        if(this.CSSView) {
                this.CSSView.remove();
        }

        this.CSSView = new CSSView({
                model: this.model['customizations'],
                namespace: '#' + this.uid + ' .view-single-video.' + this.styleID,
                template: templateCss
        });
        this.model.styleID = this.styleID;
        this.model.videos = this.moderation.get('allMessages');
        this.model.currItem = this.model.videos[this.position];

        if(this.model.currItem.user_name == constants.flex.DEFAULT_VALUE) {
            var modelParams = JSON.parse(this.model.currItem.flex_custom_parameters);
            this.model.currItem.user_name = modelParams.firstname + " " + modelParams.lastname;
        }

        this.model.currItem.message = this.model.currItem.message === constants.flex.DEFAULT_VALUE? '': this.model.currItem.message;

        this.$el.html(this.template(this.model));

        Backbone.$('head').append(this.CSSView.render().$el);
        this.mediaView.model = {'video': this.model.currItem.video,
            'images': this.model.currItem.images || this.model.placeholder_image};
        this.$('.single-video-box').html(this.mediaView.render().$el);

        return this;
    },
    renderedToDOM: function() {
        this.mediaView.renderedToDOM();
        //change svg to svg
        this.$('img.svg').each(function(){
            var $img = $(this);
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

    },
    nextVideo: function(e) {
        var position = this.position+1;
        if(position >= this.moderation.get('allMessages').length)
            position = 0;
        this.changeVideo(position, "NextButton");
    },
    prevVideo: function(e) {
        var position = this.position-1;
        if(position < 0)
            position = this.moderation.get('allMessages').length - 1;
        this.changeVideo(position, "PreviousButton");
    },
    changeVideo: function(position, tracking) {
        this.trigger('changeVideo', {'position': position, 'tracking': tracking});
    },
    checkKeydown: function(e) {
        switch (e.keyCode) {
            case 37:
                //left
                this.prevVideo();
                break;
            case 39:
                //right
                this.nextVideo();
                break;
        }
    },
    shareFb: function(e) {
        this.trigger("share:facebook", {});
    },
    shareTwitter: function(e) {
        this.trigger("share:twitter", {});
    },
    remove: function() {
        this.uid = null;
        this.model = null;
        this.styleID = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});
