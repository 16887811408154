module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% if(video) { %> <% if(!/(iframe)/.test(video)) { %> <video id=\"video-<%= styleID %>\" class=\"video-js vjs-default-skin vjs-big-play-centered vjs-fill\" controls preload=\"auto\" poster=\"<%= images %>\" data-setup='{\"customControlsOnMobile\": false}'> <source src=\"<%= video %>\" type=\"video/<%= video.substr(video.lastIndexOf('.') + 1) %>\"> </video> <% } else { %> <div class=\"videoWrapper\"> <%= video %> </div> <% } %> <% } else if(images) { %> <img src=\"<%= images %>\" class=\"media-image\"> <% } %> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if(video) { 
    ; __append(" ")
    ;  if(!/(iframe)/.test(video)) { 
    ; __append(" <video id=\"video-")
    ; __append(escapeFn( styleID ))
    ; __append("\" class=\"video-js vjs-default-skin vjs-big-play-centered vjs-fill\" controls preload=\"auto\" poster=\"")
    ; __append(escapeFn( images ))
    ; __append("\" data-setup='{\"customControlsOnMobile\": false}'> <source src=\"")
    ; __append(escapeFn( video ))
    ; __append("\" type=\"video/")
    ; __append(escapeFn( video.substr(video.lastIndexOf('.') + 1) ))
    ; __append("\"> </video> ")
    ;  } else { 
    ; __append(" <div class=\"videoWrapper\"> ")
    ; __append(escapeFn( video ))
    ; __append(" </div> ")
    ;  } 
    ; __append(" ")
    ;  } else if(images) { 
    ; __append(" <img src=\"")
    ; __append(escapeFn( images ))
    ; __append("\" class=\"media-image\"> ")
    ;  } 
    ; __append(" ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}