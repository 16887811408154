'use strict';
import _ from "lodash";
import Backbone from "backbone";
import template from './../templates/error.ejs';

export default Backbone.View.extend({

    template: template,
    tagName: 'div',
    className: 'view-error',
    events: {
        'click .btn-close': 'close'
    },
    /**
     *
     */
    initialize: function (options) {
        _.bindAll(this, 'render');
        this.errorName = options.name;
    },
    /**
     *
     */
    render: function () {
        var inlineCss = "";
        var style = this.model.customizations;

        if( style && style.color ){
            inlineCss += ( "color:" + style.color + ";" );
        }

        if( style && style.font_size ){
            inlineCss += ( "font-size:" + style.font_size + ";" );
        }

        this.model.style = inlineCss;

        this.$el.html(this.template(_.extend(this.model, {
            name: this.errorName
        })));
        return this;
    },
    /**
     *
     * @param e
     */
    close: function (e) {
        e.preventDefault();
        //this.controller.Routers.Router.navigate(this.model.button.route, {trigger: true})
        this.trigger('route', {'path': this.model.button.route})
    }

});